import "bootstrap";
import $ from "jquery";

$.fn["any"] = function () {
  return this.length > 0;
};

$.expr[":"].icontains = function (a, i, m) {
  return (
    $(a)
      .text()
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .indexOf(
        m[3]
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
      ) >= 0
  );
};

$("[data-toggle=popover]").popover({
  html: true,
  content: function () {
    return $("#popover-content").html();
  },
});

if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
  window.location = "microsoft-edge:" + window.location;
  setTimeout(function () {
    window.location = "https://go.microsoft.com/fwlink/?linkid=2135547";
  }, 1);
}

const body = document.querySelector("body");
var d = document;
var s = d.createElement("script");
s.type = "text/javascript";
s.id = "zohodeskasapscript";
s.defer = !0;
s.nonce = "Aa0fref5gregr7145";
s.src = "https://desk.zoho.eu/portal/api/web/asapApp/63480000024597836?orgId=20076175105";
const t = d.getElementsByTagName("script")[0];
t.parentNode.insertBefore(s, t);
window.ZohoDeskAsapReady = function (s) {
  var e = (window.ZohoDeskAsap__asyncalls = window.ZohoDeskAsap__asyncalls || []);
  window.ZohoDeskAsapReadyStatus
    ? (s && e.push(s), e.forEach((s) => s && s()), (window.ZohoDeskAsap__asyncalls = null))
    : s && e.push(s);

  window;
};

ZohoDeskAsapReady(function () {
  let getJwtTokenCallback = (successCallback, failureCallback) => {
    // Fetch the JWT token from your server or authentication service
    fetch("/getjwt") // replace with your actual endpoint
      .then(async (response) => {
        let token = await response.text();
        return { token: token };
      })
      .then((data) => {
        // Pass the JWT token to the success callback
        console.log("here");
        successCallback(data.token);
      })
      .catch((error) => {
        // Handle any errors and pass the error to the failure callback
        console.log(error);
        failureCallback(error);
      });
  };

  ZohoDeskAsap.invoke("login", getJwtTokenCallback);

  ZohoDeskAsap.on("onAfterUserLoggedIn", () => {
    console.log("userLogged in");
  });

  ZohoDeskAsap.set("ticket.form.prefillValues", {
    //Ex : "29262000000122184&&&63480000000023011"
    "63480000000007061&&&63480000000023011": {
      cf_site_concerne: {
        defaultValue: body.dataset.asapSite,
      },
    },
  });
});
